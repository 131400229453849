import { Component, HostListener, Input, OnInit } from '@angular/core';
import servicesData from './../../../data/services.json';

@Component({
  selector: 'app-services-contents',
  templateUrl: './services-contents.component.html',
  styleUrls: ['./services-contents.component.css']
})
export class ServicesContentsComponent implements OnInit {
  @Input() serviceCode: number;
  isWideScreen: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  constructor() { }

  checkScreenSize() {
    this.isWideScreen = window.matchMedia('(min-width: 768px)').matches;
  }


  ngOnInit(): void {
    this.checkScreenSize();
  }

  contents = servicesData;

  getContent() {
    return { 
      title:   this.contents[this.serviceCode].pricelistCollapsible.colsHeader, 
      details: this.contents[this.serviceCode].pricelistCollapsible.colsDetail, 
      design:  this.contents[this.serviceCode].pricelistCollapsible.design 
    };
  }
  
  getOtherDetails() {
    return {
      title:   this.contents[this.serviceCode].otherCollapsible.title, 
      headers: this.contents[this.serviceCode].otherCollapsible.headers, 
      details: this.contents[this.serviceCode].otherCollapsible.details, 
      design:  this.contents[this.serviceCode].otherCollapsible.design, 
    }
  }

  getData() {
    return {
      otherDetail: this.getOtherDetails(),
      content: this.getContent()
    }
  }
}
