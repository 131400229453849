import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  serviceCode: number = 1;
  isWideScreen: boolean = false;

  constructor(){}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }


  checkScreenSize() {
    this.isWideScreen = window.matchMedia('(min-width: 768px)').matches;
  }

  ngOnInit(): void {
    this.checkScreenSize();  
  }

}
