<div class="membership-body-component">
    <p class="membership-body-component-header-1">{{ header }}</p>
    
    <div class="membership-body-component-container">
        <div class="membership-body-component-container-inner">
            <p class="membership-body-component-header-2">MEMBERSHIPS</p>
            <div class="horizontal-line"></div>
            <p class="membership-body-component-session">{{ session }} SESSIONS </p>
            <div class="vertical-line"></div>
            <div class="membership-body-component-list"
                *ngFor="let item of pricelist">
                <div>{{ item.name }} </div> <div> {{ item.price }}</div>
            </div>
        </div>
    </div>
</div>