import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsDescriptionComponent } from 'src/app/components/products-description/products-description.component';
import { ProductsDisplayComponent } from 'src/app/components/products-display/products-display.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  code:number = 0;
  
  @ViewChild('productsDescription') productsDescription: ProductsDescriptionComponent;
  @ViewChild('productsDisplay') productsDisplay: ProductsDisplayComponent;

  constructor() { }

  ngOnInit(): void {
  }

  openChild(code: number){
    this.productsDescription.update(code);
    this.productsDisplay.update(code);
  }

}
