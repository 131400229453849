<app-header></app-header>
<section class="products">
    <div class="products-hero">
        <img class="products-hero-bg" src="./../../../assets/Product/PRODUCT - Hero.jpg" alt="Snow" style="width:100%;">
        <div class="products-hero-text">
            <h1>OUR PRODUCTS</h1>
            <p>
                Maximize your treatment with <br>
                our product treatment.
            </p>
        </div>
    </div>

    <div class="products-container">
        <div class="products-header">
            <h1>APRIL LASHES & BROW</h1>
        </div>
        <div class="products-contents">
            <button (click)="openChild(0)">
                <div>Purely Lashes Lash & Brow Rescue Oil</div>
                <div class="arrow">&#8599;</div>
            </button>
            <div class="products-contents-img" style="background-image: url('./../../../assets/Product/PRODUCT - Purely lashes lash and brow rescue oil.jpg');"></div>
        </div>
        <div class="products-contents">
            <button (click)="openChild(1)">
                <div>Purely Lashes Lash Growth Serum</div>
                <div class="arrow">&#8599;</div>
            </button>
            <div class="products-contents-img" style="background-image: url('./../../../assets/Product/PRODUCT - Purely lashes lash growth serum.jpg');"></div>
        </div>
        <div class="products-contents">
            <button (click)="openChild(2)">
                <div>Purely Lashes Lush Lash Mascara</div>
                <div class="arrow">&#8599;</div>
            </button>
            <div class="products-contents-img" style="background-image: url('./../../../assets/Product/PRODUCT - purely lashes lush lash mascara.jpg');"></div>
        </div>
        <div class="products-contents">
            <button (click)="openChild(3)">
                <div>Lash Aftercare Kit</div>
                <div class="arrow">&#8599;</div>
            </button>
            <div class="products-contents-img" style="background-image: url('./../../../assets/Product/PRODUCT - lash aftercare kit.jpg');"></div>
        </div>
    </div>

    <app-products-description #productsDescription [code]="code"></app-products-description>
    <app-products-display #productsDisplay [code]="code"></app-products-display>

    <app-footer></app-footer>
</section>