<app-header></app-header>
<section class="membership">
    <div class="membership-header-container">
        <div class="membership-header-1">SAVE MORE <span>with</span></div>
        <div class="membership-header-2">THIS PACKAGE </div>
        <div class="membership-header-3">
            Save more with our exclusive package! Get everything you need at a fraction <br>
            of the price when you bundle. Choose the smart option and get the best deal <br>
            with this package today!
        </div>
    </div>
    <div class="membership-body-container">
        <app-membership-component
            [header]="lashExtensionData.header"
            [session]="lashExtensionData.session"
            [pricelist]="lashExtensionData.pricelist">
        </app-membership-component>
        <app-membership-component
            [header]="lashLiftData.header"
            [session]="lashLiftData.session"
            [pricelist]="lashLiftData.pricelist">
        </app-membership-component>
    </div>
    <app-footer style="background-color: transparent;"></app-footer>
</section>