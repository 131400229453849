<app-header></app-header>
<section class="policies">
    <div class="policies-container">
        <div class="policies-1">
            <p class="policies-1-header">
                OUR POLICIES DETAILS
            </p>
        </div>
        <div class="policies-2">
            <h1 class="policies-2-header">DEPOSIT & PAYMENT</h1>
            <p class="policies-2-descriptions">
                Kindly inform us of any reschedule/cancellations at least 12 <br>
                hours prior to the booking, otherwise, the deposit will be <br>
                forfeited as a Late Cancellation Fees.<br><br>

                Deposits are refundable if rescheduling or cancellations <br>
                are made at least 12 hours in advance.<br><br>

                No shows will incur 100% of the booked appointment cost, <br>
                this also includes in the event we do not hear from you <br>
                within 10 minutes of your appointment commencent time, <br>
                we reserve the right to put it as No Shows.<br><br>

                There's a 2% Card Surcharge on all card payment and <br>
                additional 15% surcharge<br>
                for Sundays & Public Holidays <br><br><br>
            </p>
            <h1 class="policies-2-header">APPOINTMENT</h1>
            <p class="policies-2-descriptions">
                Please notify us if you are running late, we will try as much  <br>
                as we can to accommodate. <br><br>

                Lateness will result in shortened appointment time <br>
                Please make sure you come to your appointment eye  <br>
                makeup free and wash your lashes/brows thoroughly with a  <br>
                cleanser beforehand <br><br>

                Any cleaning procedure will cut into your service time <br><br>

                <span style="font-weight: bold;">Waitlist is now available, please kindly inquire through our  <br>
                    online booking platform: FRESHA</span> <br>
            </p>
        </div>
    </div>
    <app-footer 
        style="width: 100%; display: flex; background-color: #f7e1e1; justify-content: center;">
    </app-footer>
</section>