import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './components/header/header.component';
import { BookOnlineComponent } from './pages/book-online/book-online.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { FooterComponent } from './components/footer/footer.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { MembershipComponentComponent } from './components/membership-component/membership-component.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductsDescriptionComponent } from './components/products-description/products-description.component';
import { ProductsDisplayComponent } from './components/products-display/products-display.component';
import { ServicesComponent } from './pages/services/services.component';
import { ServicesContentsComponent } from './components/services-contents/services-contents.component';
import { ServicesCollapsibleComponent } from './components/services-collapsible/services-collapsible.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    BookOnlineComponent,
    PoliciesComponent,
    GalleryComponent,
    FooterComponent,
    MembershipComponent,
    MembershipComponentComponent,
    ProductsComponent,
    ProductsDescriptionComponent,
    ProductsDisplayComponent,
    ServicesComponent,
    ServicesCollapsibleComponent,
    ServicesContentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
