import { Component, OnInit } from '@angular/core';
import membershipData from './../../../data/membership.json';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  lashExtensionData = membershipData[0];  // First section of JSON
  lashLiftData = membershipData[1];       // Second section of JSON

  constructor() { }

  ngOnInit(): void {
    console.log(this.lashExtensionData);
    console.log(this.lashLiftData);
  }

}
