import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-book-online',
  templateUrl: './book-online.component.html',
  styleUrls: ['./book-online.component.css']
})
export class BookOnlineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
