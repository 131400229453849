<header>
    <div class="logo">
      <!-- Replace with your logo -->
      <img src="./../../../assets/Logo/Logo-Resized.png" class="header-logo" alt="Logo">
    </div>
    <nav>
      <ul class="nav-links">
        <li><a routerLink="/home">HOME</a></li>
        <li><a routerLink="/services">SERVICES</a></li>
        <li><a routerLink="/membership">MEMBERSHIP</a></li>
        <li><a routerLink="/products">PRODUCTS</a></li>
        <li><a routerLink="/policies">POLICIES</a></li>
        <li><a routerLink="/gallery">GALLERY</a></li>
        <li><a routerLink="/book-online">BOOK ONLINE</a></li>
      </ul>
      <div class="hamburger" (click)="toggleMenu()" [ngClass]="{'open': isMenuOpen}">
        <!-- Hamburger Icon -->
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
  </header>
  
  <!-- For smaller screen menu -->
  <ul class="nav-links-mobile" [ngClass]="{'open': isMenuOpen, 'slide': isMenuOpen}">
    <li><a routerLink="/home" (click)="closeMenu()">HOME</a></li>
    <li><a routerLink="/services" (click)="closeMenu()">SERVICES</a></li>
    <li><a routerLink="/membership" (click)="closeMenu()">MEMBERSHIP</a></li>
    <li><a routerLink="/products" (click)="closeMenu()">PRODUCTS</a></li>
    <li><a routerLink="/policies" (click)="closeMenu()">POLICIES</a></li>
    <li><a routerLink="/gallery" (click)="closeMenu()">GALLERY</a></li>
    <li><a routerLink="/book-online">BOOK ONLINE</a></li>
  </ul>
  