import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-component',
  templateUrl: './membership-component.component.html',
  styleUrls: ['./membership-component.component.css']
})
export class MembershipComponentComponent implements OnInit {
  @Input() header: string;
  @Input() session: string;
  @Input() pricelist: any;

  constructor() { }

  ngOnInit(): void {
  }

}
