import { Component, Input, OnInit } from '@angular/core';
import productDescriptionsData from './../../../data/products.json';

@Component({
  selector: 'app-products-description',
  templateUrl: './products-description.component.html',
  styleUrls: ['./products-description.component.css']
})
export class ProductsDescriptionComponent implements OnInit {
  @Input() code: number;
  productDesc = productDescriptionsData;

  constructor() { }

  ngOnInit(): void { }

  update(code: number){
    this.code = code;
  }

}
