import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { BookOnlineComponent } from './pages/book-online/book-online.component';
import { PoliciesComponent } from './pages/policies/policies.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { MembershipComponent } from './pages/membership/membership.component';
import { ProductsComponent } from './pages/products/products.component';
import { ServicesComponent } from './pages/services/services.component';

// Add your components here
const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'book-online', component: BookOnlineComponent },
    { path: 'policies', component: PoliciesComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'membership', component: MembershipComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'services', component: ServicesComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' }, // default route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
