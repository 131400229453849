<div class="products-description-component-container">
    <div class="products-description-contents-1">
        <h1 style="white-space:pre-line;">{{ productDesc[code].title }}</h1>
    </div>
    <div class="products-description-contents-2">
        <div class="products-description-contents-2-1">
            <p style="white-space:pre-line;">{{ productDesc[code].first }}</p>
        </div>
        <div class="products-description-contents-2-2">
            <p style="white-space:pre-line;">{{ productDesc[code].last }}</p>
        </div>
    </div>
</div>
