<div *ngIf="(data.otherDetail | json) != '{}'" class="collapsible-container" id="open-{{isOtherDetailOpen}}">
  <div class="collapsible-header" (click)="toggleOtherDetail()">
    <span>{{ data.otherDetail.title }}</span>
    <span class="plus-icon">{{ isOtherDetailOpen ? '-' : '+' }}</span>
  </div>
  <div [@expandCollapse]="isOtherDetailOpen ? 'expanded' : 'collapsed'" class="collapsible-detail-container">
    <div class="collapsible-detail-text-container">
      <ng-container *ngIf="data.otherDetail.headers.length > 0;">
        <div *ngFor="let header of data.otherDetail.headers; let i = index" class="collapsible-detail-text">
          <p class="collapsible-detail-text-header"><strong>{{ header }}</strong></p>
          <p class="collapsible-detail-text-detail">{{ data.otherDetail.details[i] }}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>


<div class="collapsible-container" id="open-{{isPricelistOpen}}">
    <div class="collapsible-header" (click)="togglePricelist()">
      <span> {{ data.content.title }} </span>
      <span class="plus-icon">{{ isPricelistOpen ? '-' : '+' }}</span>
    </div>
    <div [@expandCollapse]="isPricelistOpen ? 'expanded' : 'collapsed'" class="collapsible-detail-container">
      <div class="collapsible-detail-image-container">
        <img src="{{ data.content.details }}" alt="Collapsed Informations" class="collapsible-detail-image">
      </div>
    </div>
  </div>
  