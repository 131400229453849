<!-- <p>Component B received: {{ code }}</p> -->
<div class="products-display-component-container" style="background-image: url('{{ displayPath[code] }}');">
    <div class="products-display-component-container-inner" [attr.id]="'products-display-component-container-id-' + code" >
        <div *ngIf="code == 0" [attr.id]="'products-display-component-text-content-id-' + code" class="products-display-component-text-content" >
            <div class="text products-display-component-text-content-id-0-parts-1">
                Vegan <br/>
                Cruelty Free <br/>
                Australian Owned &amp; Run
            </div>
            <div class="text products-display-component-text-content-id-0-parts-2">
                We advise to use this product regularly for <br/>
                6-12 weeks for ultimate results.
            </div>
            <div class="text products-display-component-text-content-id-0-parts-3">
                Not recommended for use with lash <br/>
                extensions. <br/>
                Not Suitable for NUT allergy sufferers.
            </div>
            <div class="text products-display-component-text-content-id-0-parts-4">
                Apply to base of lashes &amp; on the eyelashes <br/>
                &amp; brows using the wand before bed each <br/>
                night.
            </div>
        </div>

        <div *ngIf="code == 1" [attr.id]="'products-display-component-text-content-id-' + code" class="products-display-component-text-content" >
            <div class="text products-display-component-text-content-id-1-parts-1">
                Vegan <br/>
                Cruelty Free <br/>
                Australian Owned &amp; Run
            </div>
            <div class="text products-display-component-text-content-id-1-parts-2">
                Apply to the base of eyelashes using the <br>
                wand after washing your face in the <br>
                morning, before any eye make up.
            </div>
        </div>

        <div *ngIf="code == 2" [attr.id]="'products-display-component-text-content-id-' + code" class="products-display-component-text-content" >
            <div class="text products-display-component-text-content-id-2-parts-1">
                Easy to apply & remove with just water <br>
                Gentle formula that doesn't irritate your <br>
                eyes.
            </div>
            <div class="text products-display-component-text-content-id-2-parts-2">
                Suitable for all ages & eyes. <br>
                Cruelty-free <br>
                Australian owned
            </div>
            <div class="text products-display-component-text-content-id-2-parts-3">
                No Parabens & no harsh drying ingredients. <br>
                Contains nourishing ingredients Biotin, <br>
                Keratin & peptides to help grow your lashes! 
            </div>
        </div>

        <div *ngIf="code == 3" [attr.id]="'products-display-component-text-content-id-' + code" class="products-display-component-text-content" >
            <div class="text products-display-component-text-content-id-3-parts-1">
                ophthalmologically tested <br>
                approved for sensitive eyes <br>
                100% oil free <br>
                pH balanced <br>
                proudly Australian Made <br>
                vegan friendly
            </div>
            <div class="text products-display-component-text-content-id-3-parts-2">
                1x 60ml Lash Shampoo <br>
                1x Lash Shampoo Brush <br>
                1x Lash Spoolie <br>
                1x Lash Rinse Bottle <br>
                1x Face Towel <br>
                1x Waterproof Mini Bag <br>
                BONUS Mini Fan
            </div>
        </div>
    </div>
</div>
