import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-display',
  templateUrl: './products-display.component.html',
  styleUrls: ['./products-display.component.css']
})
export class ProductsDisplayComponent implements OnInit {
  displayPath: any = [
    './../../../assets/Product/PRODUCT - Purely lashes lash and brow rescue oil.jpg',
    './../../../assets/Product/PRODUCT - Purely lashes lash growth serum.jpg',
    './../../../assets/Product/PRODUCT - purely lashes lush lash mascara.jpg',
    './../../../assets/Product/PRODUCT - lash aftercare kit.jpg'
  ]

  @Input() code: number;

  constructor() { }

  ngOnInit(): void {
    code: 0;
  }

  update(code: number){
    this.code = code;
  }


}
