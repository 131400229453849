<app-header></app-header>
<section class="services">
    <div class="services-header">OUR SERVICES</div>
    <div class="app-services-contents-container">
        <app-services-contents [serviceCode]=0></app-services-contents>
        <app-services-contents [serviceCode]=1></app-services-contents>
        <app-services-contents [serviceCode]=2></app-services-contents>
        <app-services-contents [serviceCode]=3></app-services-contents>
    </div>

    <div *ngIf="isWideScreen">
        <div class="desktop-services-business-hours">
            <div class="desktop-services-business-hours-containers-1">
                <div class="desktop-services-business-hours-containers-1-text">BUSINESS <br>HOURS</div>
                <div class="desktop-services-business-hours-list">
                    <div class="desktop-services-business-hours-list-text-1">
                        Mon : 9 am - 7 pm <br>
                        Tue : 9 am - 7 pm <br>
                        Wed : 8 am - 8 pm <br>
                        Thu : 9 am - 8 pm
                    </div>
                    <div class="desktop-services-business-hours-list-text-2">
                        Fri : 8 am - 7 pm <br>
                        Sat : 9 am - 5 pm <br>
                        Sun : by appointment
                    </div>
                    <div class="desktop-services-business-hours-list-text-3">
                        Notes: <br>
                        call 0473 319 917 <br>
                        24hrs in advance to book sunday
                    </div>
                </div>
            </div>
            <div class="desktop-services-business-hours-containers-2">                
                <div class="desktop-services-business-hours-containers-1-images-container">
                    <div class="desktop-services-business-hours-containers-1-images-contents" alt="business-hours"></div>
                    <div class="desktop-services-business-hours-containers-2-images-contents" alt="business-hours"></div>
                    <div class="desktop-services-business-hours-containers-3-images-contents" alt="business-hours"></div>
                </div>
                <app-footer style="margin-top: 2.5vh; color: black;"></app-footer>
            </div>
        </div>
    </div>
      
    <div *ngIf="!isWideScreen">
        <div class="services-business-hours">
            <div class="services-business-hours-containers-1">
                <div class="services-business-hours-containers-1-text">BUSINESS <br>HOURS</div>
                <div class="services-business-hours-containers-1-images-container">
                    <div class="services-business-hours-containers-1-images-contents" alt="business-hours"></div>
                    <div class="services-business-hours-containers-2-images-contents" alt="business-hours"></div>
                    <div class="services-business-hours-containers-3-images-contents" alt="business-hours"></div>
                </div>
            </div>
            <div class="services-business-hours-containers-2">
                <div class="services-business-hours-list-header">BUSINESS <br>HOURS</div>
                <div class="services-business-hours-list">
                    <div class="services-business-hours-list-text-1">
                        <span class="bold">Mon</span> : 9 am - 7 pm <br>
                        <span class="bold">Tue</span> : 9 am - 7 pm <br>
                        <span class="bold">Wed</span> : 8 am - 8 pm <br>
                        <span class="bold">Thu</span> : 9 am - 8 pm
                    </div>
                    <div class="services-business-hours-list-text-2">
                        <span class="bold">Fri</span> : 8 am - 7 pm <br>
                        <span class="bold">Sat</span> : 9 am - 5 pm <br>
                        <span class="bold">Sun</span> : by appointment
                    </div>
                    <div class="services-business-hours-list-text-3">
                        Notes: <br>
                        call 0473 319 917 <br>
                        24hrs in advance to book sunday
                    </div>
                </div>
                <div class="centered-line"></div>
                <app-footer style="margin-top: 0;"></app-footer>
            </div>
        </div>
    </div>
</section>