import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-services-collapsible',
  templateUrl: './services-collapsible.component.html',
  styleUrls: ['./services-collapsible.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
          height: '0px',
          overflow: 'hidden',
          opacity: 0
      })),
      state('expanded', style({
          height: '*',
          opacity: 1
      })),
      transition('collapsed <=> expanded', [
          animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class ServicesCollapsibleComponent {
  @Input() data: {
    content: {
      title: string, 
      details: string, 
      design: number 
    },
    otherDetail: {
      title: string,
      headers: string[],
      details: string[],
      design: number
    }
  };
  isOtherDetailOpen = false;
  isPricelistOpen = false;

  ngOnInit() {
    console.log('Data received in component:', this.data);
  }

  toggleOtherDetail() {
    this.isOtherDetailOpen = !this.isOtherDetailOpen;
  }

  togglePricelist() {
    this.isPricelistOpen = !this.isPricelistOpen;
  }

}
