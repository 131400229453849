<!-- services-contents.component.html -->
<div class="services-contents-container">
    <div class="services-contents-image">
        <img src="{{ contents[serviceCode].img }}" alt="Content {{ serviceCode }}">
    </div>
    <div *ngIf="!isWideScreen">
        <div class="services-contents-main-part">
            <div class="services-contents-main-part-left">
                <div class="services-contents-main-part-left-num">0{{ serviceCode+1 }}.</div>
                <div class="services-contents-main-part-left-text">{{ contents[serviceCode].sideText }}</div>
                <img class="services-contents-main-part-left-logo" src="{{ contents[serviceCode].logo }}" alt="Logo">
            </div>
            <div class="services-contents-main-part-right">
                <div class="services-contents-main-part-right-title"> {{ contents[serviceCode].title }} </div>
                <div class="services-contents-main-part-right-descriptions"> {{ contents[serviceCode].details }} </div>
            </div>
            <div class="services-contents-main-part-collapsible">
                <app-services-collapsible [data]="getData()"></app-services-collapsible>
            </div>
        </div>
    </div>
    <div *ngIf="isWideScreen">
        <div class="desktop-services-contents-main-part">
            <div class="desktop-services-contents-main-part-left">
                <div class="desktop-services-contents-main-part-left-num">0{{ serviceCode+1 }}.</div>
                <div class="desktop-services-contents-main-part-left-text">{{ contents[serviceCode].sideText }}</div>
                <img class="desktop-services-contents-main-part-left-logo" src="{{ contents[serviceCode].logo }}" alt="Logo">
            </div>
            <div class="desktop-services-contents-main-part-right">
                <div class="desktop-services-contents-main-part-right-title"> {{ contents[serviceCode].title }} </div>
                <div class="desktop-services-contents-main-part-right-descriptions"> {{ contents[serviceCode].details }} </div>
                <!-- <app-services-collapsible [data]="getData()"></app-services-collapsible> -->
            </div>
            <div class="desktop-services-contents-main-part-collapsible">
                <app-services-collapsible [data]="getData()"></app-services-collapsible>
            </div>
        </div>
    </div>
</div>